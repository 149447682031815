<template>
    <div
        @touchmove.prevent
        class="login"
    >
        <div class="content">
            <scroller>
                <div class="login-box">
                    <div class="logo-box">
                        <img
                            src="@/assets/login/logo.png"
                            alt=""
                        >
                        <p>飞银惠生活</p>
                    </div>
                    <div class="login-form">
                        <van-form
                            @submit="onSubmit"
                            autocomplete="off"
                        >
                            <div class="item-wrapper">
                                <div class="form-item">
                                    <img
                                        class="icon"
                                        src="@/assets/login/tel.png"
                                        alt=""
                                    >
                                    <van-field
                                        v-model="params.tel"
                                        name="tel"
                                        :rules="[{ required: true, message: '手机号不能为空' },{ validator:pattenTel, message: '手机号格式错误' }]"
                                        placeholder="请输入手机号"
                                    />
                                </div>
                                <div class="form-item">
                                    <img
                                        class="icon"
                                        src="@/assets/login/code.png"
                                        alt=""
                                    >
                                    <van-field
                                        v-model="params.code"
                                        name="code"
                                        placeholder="请输入验证密码"
                                        :rules="[{ required: true, message: '验证密码不能为空' }]"
                                    >
                                    </van-field>
                                    <div
                                        :disabled="codeBtn.disabled"
                                        class="get-code-btn"
                                        size="small"
                                        :class="codeBtn.disabled ? 'disabled':'use'"
                                        :color="codeBtn.disabled ? '#CCCCCC' : '#e31802' "
                                        @click.stop="getSms"
                                    >{{codeBtn.disabled ? `${codeBtn.count}s后可重试`:'发送验证码'}}</div>
                                    <!-- <van-button
                                        :disabled="codeBtn.disabled"
                                        class="get-code-btn"
                                        size="small"
                                        :color="codeBtn.disabled ? '#CCCCCC' : '#07c160' "
                                        @click.stop="getSms"
                                    >{{codeBtn.disabled ? `${codeBtn.count}s后可重试`:'发送验证码'}}</van-button> -->
                                </div>
                                <div class="form-item">
                                    <img
                                        class="icon"
                                        src="@/assets/login/password.png"
                                        alt=""
                                    >
                                    <van-field
                                        v-model="params.password"
                                        name="pass"
                                        type="password"
                                        :rules="[{ required: true, message: '登录密码不能为空'},{ validator: vaildPassLength, message: '密码长度在8~20个字符之间'}, {validator: vaildPass,message:'两次密码不一致'}]"
                                        placeholder="设置登录密码"
                                    />
                                </div>
                                <div class="form-item">
                                    <img
                                        class="icon"
                                        src="@/assets/login/password.png"
                                        alt=""
                                    >
                                    <van-field
                                        v-model="params.pass"
                                        name="oncepass"
                                        type="password"
                                        :rules="[{ required: true, message: '再次输入密码' },,{ validator: vaildPassLength, message: '密码长度在8~20个字符之间'},{validator: vaildPass,message:'两次密码不一致'}]"
                                        placeholder="再次输入密码"
                                    />
                                </div>
                                <div class="form-item invitation">
                                    <div class="i-left">邀请码：</div>
                                    <div class="i-right">{{interviewCode}}</div>
                                </div>
                            </div>
                            <van-button
                                color="#E31802"
                                class="login-btn"
                                block
                                native-type="submit"
                            >立即注册</van-button>
                        </van-form>
                    </div>

                    <div class="agreement">
                        <van-checkbox
                            class="check-box"
                            shape="square"
                            checked-color="#E31802"
                            v-model="argument"
                        >

                        </van-checkbox>
                        <div class="dec-box">
                            <span class="gray">注册即代表你已同意</span><span
                                class="blue"
                                @click.stop="goAgreement(1)"
                            >《飞银惠生活用户协议》</span>、<span
                                class="blue"
                                @click.stop=" goAgreement(2)"
                            >《隐私政策》</span>
                        </div>
                    </div>
                </div>
            </scroller>

        </div>
    </div>
</template>
<script>

export default {

    data() {
        return {
            params: {
                tel: "",
                password: "", //密码
                pass: "", //确认密码
                code: "", //二维码

            },
            interviewCode: "", //邀请码
            argument: '', //是否同意协议
            reg: {
                tel: /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            },
            codeBtn: {
                disabled: false,
                text: "",
                timer: null,
                count: 60,
            },

        }

    },
    methods: {
        vaildPassLength(val) {
            if (val) {
                if (val.length >= 8 && val.length <= 20) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        vaildPass(val) {
            let matchRes = true;
            if (this.params.password && this.params.pass) {
                if (this.params.password != this.params.pass) {
                    matchRes = false;
                }
            }
            return matchRes;
        },
        pattenTel(val) {
            return this.reg.tel.test(val);
        },
        toast(str, type) {
            switch (type) {
                case "success":
                    this.$toast.success(str);
                    break;
                default:
                    this.$toast(str);
                    break;
            }

        },

        // 获取验证码
        getSms() {
            if (!this.params.tel) {
                this.toast('请输入手机号')
                return;
            }
            let tel_reg = this.reg.tel;
            if (!tel_reg.test(this.params.tel)) {
                this.toast('请输入正确的手机号')
                return;
            }
            this.$toast.loading('发送中...');

            this.codeBtn.disabled = !this.codeBtn.disabled;
            this.codeBtn.timer = setInterval(() => {
                this.codeBtn.count = this.codeBtn.count - 1;
                if (this.codeBtn.count < 1) {
                    this.codeBtn.disabled = !this.codeBtn.disabled;
                    // 停止倒计时
                    clearInterval(this.codeBtn.timer)
                    this.codeBtn.timer = null;
                    this.codeBtn.count = 60;
                }
            }, 1000)
            this.$axios({
                methods: "get",
                url: "/mall-user/phone/code",
                params: {
                    phone: this.params.tel,
                    type: 2
                }
            }).then(res => {
                this.$toast.clear();
                let data = res.data;
                switch (data.code) { }
                if (data.code == 200) {
                    this.toast('发送成功')
                } else {
                    this.$toast.fail(data.msg)
                }

            }).catch(err => {
            })
        },
        // 登录
        onSubmit(values) {
            if (values) {
                if (!this.argument) {
                    this.toast('请勾选协议!');
                    return
                }
                this.$axios.post('/auth/api/register/app',
                    {
                        username: this.params.tel,
                        password: this.params.password,
                        smsCode: this.params.code,
                        interviewCode: this.interviewCode
                    },
                    {
                        headers: {
                            "sourcetype": "2",
                            "shopid": "1",
                            "tenantid": "1",
                            "Content-Type": "application/json;charset=utf-8"
                        }
                    }
                ).then(res => {
                    this.$toast.clear();
                    let data = res.data;
                    switch (data.code) {
                        case 200:
                            this.$toast.success('注册成功')
                            setTimeout(() => {
                                this.$router.push('/login/success')
                            }, 500)
                            break;
                        case 500:
                            this.$toast.fail(data.msg);
                            break;
                    }
                }).catch((err) => {
                    this.$toast.fail('出错了，检查网络是否正常');
                })


            }



        },
        goAgreement(type) {
            let url = ""
            switch (type) {
                case 1:
                    //用户协议
                    url = "https://www.fypay-xm.com/openapp/privacy.html";
                    break;
                case 2:
                    // 隐私协议
                    url = "https://www.fypay-xm.com/openapp/register.html";
                    break;
            }
            window.location.href = url;
        },

    },
    beforeCreate() {
    },
    created() {
        let params = this.$route.fullPath.split('?');
        if (params[1]) {
            let arr = params[1].split("&");

            arr.forEach(item => {

                let iarr = item.split('=');
                let key = iarr[0];
                let val = decodeURIComponent(iarr[1]);
                switch (key) {
                    case "interviewCode":
                        this.interviewCode = this.$Base64.decode(val);

                        break;
                }
            });
        }

    },
    beforeMount() {

    },
    mounted() {

    },
    beforeUpdate() {

    },
    updated() {

    },
    beforeDestroy() {
        this.codeBtn.timer && clearInterval(this.codeBtn.timer)
    },
    destroyed() {

    },
}
</script>
<style scoped lang='scss'>
@import "./index.scss";
</style>
